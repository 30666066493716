

<template>
	<div class="tcontainer">
		<div class="clearfix ttitle">
			<div class="title">
				Rate Chart
			</div>
		</div>
		<div style="padding: 30px 0; background: #fff; margin-bottom: 10px; border: 1px solid #eee; border-top: 0;">
			<div class="container">
				<div class="row">
					<div class="col-sm-3"></div>
					<div class="col-sm-6">
						<table class="table table-bordered">
							<thead>
								<tr>
									<th>Category</th>
									<th>Price</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<div style="max-width: 450px">
											Single View
											<span style="font-size: 14px;">( Skull, Shoulder, Chest, Spine, Hip - Single exposure
												Hand &amp; Foot - Single or double exposure )</span>
										</div>
									</td>
									<td style="width: 100px">&#8377; {{ center.price_single_view }}</td>
								</tr>
								<tr>
									<td>
										<div style="max-width: 450px">
											Double View
											<span style="font-size: 14px;">( Skull, Shoulder, Chest, Spine, Hip - double exposure
												)</span>
										</div>
									</td>
									<td>&#8377; {{ center.price_double_view }}</td>
								</tr>
								<tr>
									<td>
										<div style="max-width: 450px">
											Multi View
											<span style="font-size: 14px;">( Spl Case IVF, IVP, HSG, Barium )</span>
										</div>
									</td>
									<td>&#8377; {{ center.price_multi_view }}</td>
								</tr>
								<tr>
									<td>
										<div style="max-width: 450px">
											Both View
											<span style="font-size: 14px;">( BOTH = LEFT &amp; RIGHT Parts )</span>
										</div>
									</td>
									<td>&#8377; {{ center.price_both_view }}</td>
								</tr>
								<tr>
									<td>Other View</td>
									<td>&#8377; {{ center.price_other_view }}</td>
								</tr>
								<tr>
									<td>ECG Rate</td>
									<td>&#8377; {{ center.cardiology_price }}</td>
								</tr>
								<tr>
									<td>TMT Rate</td>
									<td>&#8377; {{ center.tmt_price }}</td>
								</tr>
								<tr>
									<td>EEG Rate</td>
									<td>&#8377; {{ center.neurology_price }}</td>
								</tr>
								<tr>
									<td>Minimum Balance</td>
									<td>&#8377; {{ center.minimum_balance }}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="col-sm-3"></div>
				</div>
			</div>
		</div>


	</div>
</template>

<script>
export default {
	data() {
		return {
			center: {}
		}
	},
	mounted() {
		this.loadCenter();
	},
	methods: {
		loadCenter() {
			this.get("center/load-center").then(res => {
				this.center = res;
			});
		},
	}
}
</script>
<style scoped>

</style>
